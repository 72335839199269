import React from 'react';
import { IonBadge, IonCard, IonContent, IonItem, IonLabel, IonList, IonPage, IonRefresher, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-dashboard.scss';

//	S T A T E   -   P R O P S

import { props___SbDiscoverFeedPage, state___SbDiscoverFeedPage, state___SbDiscoverFeedPage___default } from './sb-dashboard.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___ticket___object } from '../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../components/com-event/com-event';
import ComEventNullComponent from '../../components/com-event-null/com-event-null';
import ComEventTimeScheduleComponent from '../../components/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComSpinnerRefreshComponent from '../../components/com-spinner-refresh/com-spinner-refresh';

//	M O D A L S

import MdTicketEditModal from '../../modals/md-ticket-edit/md-ticket-edit';
import MdTicketViewModal from '../../modals/md-ticket-view/md-ticket-view';
import ComSuggestionNearbyComponent from '../../components/com-suggestion-nearby/com-suggestion-nearby';

//	C L A S S

class SbDashboardPage extends React.Component<props___SbDiscoverFeedPage, state___SbDiscoverFeedPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbDiscoverFeedPage,
	)
	{
		super(props);
		this.state = state___SbDiscoverFeedPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___stored___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___stored___event_id !== null)
		{
			const ___temp___selected_event_props: type___api___response = await this._API.events___read___single(___stored___event_id);
			this.setState({
				event___props: ___temp___selected_event_props.data,
				event___props___loading: false
			});
		}
		else
		{
			this.setState({
				event___props___loading: false
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page_refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___event();
		___e.detail.complete();
	}

	handle___preview___ticket_created = (___ticket_sumbitted_object: type___ticket___object | null) => {
		this.setState({
			ticket___is_creating: false,
		}, () => {
			if (___ticket_sumbitted_object) {
				this.setState({
					ticket_created_preview___visible: true,
					ticket_created_preview___object: ___ticket_sumbitted_object,
				});
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___event();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>
			<IonPage>

				<MdTicketEditModal isOpen={ this.state.event___props && this.state.ticket___is_creating && this.state.ticket___is_creating___type } ticket_type={ this.state.ticket___is_creating___type } ticket_id={ null } event___onDidDismiss={ this.handle___preview___ticket_created }/>
				<MdTicketViewModal isOpen={ this.state.ticket_created_preview___visible && this.state.ticket_created_preview___object } ticket_object={ this.state.ticket_created_preview___object } event___onDidDismiss={() => { this.setState({ ticket_created_preview___visible: false, ticket_created_preview___object: null }); }}/>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page_refresh }>
						<ComSpinnerRefreshComponent/>
					</IonRefresher>

					<section className="sb-dashboard-page---container">

						<div className="sb-dashboard-page---img-header">
							<img src="/assets/logo-horizontal.svg"/>
						</div>

						<br />

						{(this.state.event___props___loading === true) ? <>
							<ComSpinnerComponent size="small"/>
						</> : <>
							{(this.state.event___props) ? <>
								<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
								<br />
								<Link to={ '/events/view/' + this.state.event___props['event_id'] }>
									<IonCard>
										<ComEventComponent event___props={ this.state.event___props }/>
									</IonCard>
								</Link>
							</> : <>
								<Link to="/events/list">
									<ComEventNullComponent />
								</Link>
							</>}
						</>}

						<Link to="/events/list" className="sb-dashboard---event-changer">
							<i className="fas fa-exchange-alt"></i>
							<span>cambia evento</span>
						</Link>

						<br />
						<br />
						<br />

						<a href="https://www.nearbycommunity.it/" target="_blank">
							<ComSuggestionNearbyComponent />
						</a>

						<br />

						{(this.state.event___props) ? <>
							
							{(this.props.me && (this.props.me['user_role_is___admin'] === true || this.props.me['user_role_is___cashdesk'] === true)) ? <>
								<IonCard>
									<IonList>
										<IonItem button={ true } className="is-ion-item-button" routerLink="/cashdesk">
											<i className="fas fa-cash-register"></i>
											<IonLabel>CashDesk</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
								<br />
							</> : <></>}

							<IonCard>
								<IonList>
									{(this.state.event___props['event_ended'] === false) ? <>
										{/*
											{(this.state.event___props['event_settings___tickets_standards'] === true) ? <>
												<IonItem className="is-ion-item-button" onClick={() => { this.setState({ ticket___is_creating: true, ticket___is_creating___type: 'standard' }); }}>
													<i className="fas fa-qrcode"></i>
													<IonLabel><b>Aggiungi Ticket</b></IonLabel>
												</IonItem>
											</> : <></>}
										*/}
										{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
											<IonItem className="is-ion-item-button" onClick={() => { this.setState({ ticket___is_creating: true, ticket___is_creating___type: 'invite' }); }}>
												<i className="fas fa-calendar-week"></i>
												<IonLabel><b>Aggiungi Invito in Lista</b></IonLabel>
											</IonItem>
										</> : <></>}
										{/*
											{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
												<IonItem className="is-ion-item-button" onClick={() => { this.setState({ ticket___is_creating: true, ticket___is_creating___type: 'share_invite' }); }}>
													<i className="fas fa-qrcode"></i>
													<IonLabel><b>Condividi QR Invito</b></IonLabel>
												</IonItem>
											</> : <></>}
										*/}
										{/*
											{(this.state.event___props['event_settings___tickets_online'] === true) ? <>
												<IonItem className="is-ion-item-button" onClick={() => { this.setState({ ticket___is_creating: true, ticket___is_creating___type: 'share_online' }); }}>
													<i className="fas fa-external-link-alt"></i>
													<IonLabel><b>Condividi per Ticket Online</b></IonLabel>
												</IonItem>
											</> : <></>}
										*/}
									</> : <></>}
								</IonList>
							</IonCard>

							<br />

							<IonCard>
								<IonList>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/tickets/list">
										<i className="fas fa-list"></i>
										<IonLabel>Lista Tickets</IonLabel>
									</IonItem>
									{(this.props.me && this.props.me['user_role_is___admin'] === true && this.state.event___props) ? <>
										<IonItem button={ true } className="is-ion-item-button" routerLink={ '/analytics/global/' + this.state.event___props['event_id'] }>
											<i className="fas fa-chart-line"></i>
											<IonLabel>Statistiche Evento</IonLabel>
										</IonItem>
									</> : <></>}
									<IonItem button={ true } className="is-ion-item-button" routerLink={ '/analytics/me/' + this.state.event___props['event_id'] }>
										<i className="fas fa-chart-line"></i>
										<IonLabel>Statistiche Personali</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>
							
							<br />

						</> : <></>}

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/events/list">
									<i className="fas fa-calendar-alt"></i>
									<IonLabel>Lista Eventi</IonLabel>
								</IonItem>
								{(this.props.me && this.props.me['user_role_is___admin'] === true) ? <>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/users/list">
										<i className="fas fa-users"></i>
										<IonLabel>Staff Organizzazione</IonLabel>
									</IonItem>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/payments/users">
										<i className="far fa-money-bill-alt"></i>
										<IonLabel>Pagamenti Staff</IonLabel>
										<IonBadge>beta</IonBadge>
									</IonItem>
								</> : <></>}
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings">
									<i className="fas fa-cog"></i>
									<IonLabel>Impostazioni</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

					</section>

					<br />
					<br />
					<br />

				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbDashboardPage);