import React, { createRef } from 'react';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonModal, IonSkeletonText, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-ticket-view.scss';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	S T A T E   -   P R O P S

import { props___MdTicketViewModal, state___MdTicketViewModal, state___MdTicketViewModal___default } from './md-ticket-view.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';
import { funcs_tickets } from '../../funcs/funcs.tickets';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComButtonTicketShareComponent from '../../components/com-button-ticket-share/com-button-ticket-share';
import ComQrViewComponent from '../../components/com-qr-view/com-qr-view';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComTicketTypeComponent from '../../components/com-ticket-type/com-ticket-type';

//	C L A S S

class MdTicketViewModal extends React.Component<props___MdTicketViewModal, state___MdTicketViewModal> {

//#region 																							C O N F I G

	private readonly MdTicketViewModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___tickets: funcs_tickets = new funcs_tickets();

	private MdTicketViewModal___ticket_dom_reference: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTicketViewModal,
	) {
		super(props);
		this.state = state___MdTicketViewModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___temp___user___props: type___api___response = (this.props.ticket_object!['ticket_pr']) ? await this._API.users___read___single('id', this.props.ticket_object!['ticket_pr']) : await this._API.users___read___single('me');
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___is_loading: false,
		});
	}

	private read_props___event = async () => {
		if (this.props.ticket_object!['ticket_event']) {
			const ___temp___ticket_event_props: type___api___response = await this._API.events___read___single(this.props.ticket_object!['ticket_event']);
			this.setState({
				event___props: ___temp___ticket_event_props.data,
				event___props___is_loading: false
			});
		}
		else
		{
			console.log('error in event');
		}
	}

	private read_props___ticket = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.tickets___read___single(this.props.ticket_object!['ticket_id']!);
		this.setState({
			ticket___type: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_type'] : this.state.ticket___type,
			ticket___props: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data'] : null,
			ticket___props___is_loading: false,
		}, async () => {
			switch (this.state.ticket___type) {
				case 'invite':
					await this.read_props___event();
					if (this.state.ticket___props && this.state.ticket___props['ticket_pr']) { await this.read_props___user(); }
					else { this.setState({ user___props___is_loading: false }); }
				break;
				default:
				case 'online':
				case 'standard':
					await this.read_props___ticket_type();
				break;
			}
		});
	}

	private read_props___ticket_type = async () => {
		if (this.state.ticket___type !== null && this.state.ticket___type !== 'invite') {
			const ___temp___ticket_type_props: type___api___response = await this._API.tickets_types___read___single(this.state.ticket___props['ticket_type_reference']);
			this.setState({
				ticket___type_props: ___temp___ticket_type_props.data,
				ticket___type_props___is_loading: false
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {

		console.log('this.props.ticket_object',this.props.ticket_object)

		if (this.props.ticket_object) {
			this.setState({
				ticket___type: this.props.ticket_object['ticket_type'],
			}, async () => {
				if (this.props.ticket_object!.ticket_id)
				{
					await this.read_props___ticket();
				}
				else
				{
					await this.read_props___event();
					await this.read_props___user();
				}	
			})
		}
	}

	private event___onDidDismiss() : void {
		this.setState({
			...state___MdTicketViewModal___default
		}, () => {
			this.props.event___onDidDismiss();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {

		const ___share_url___only_code: string = this.funcs___tickets.ticket___encrypt___from_object(this.props.ticket_object!);

		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdTicketViewModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>chiudi</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<section ref={ this.MdTicketViewModal___ticket_dom_reference } className="md-ticket-view---container">
						{(this.state.ticket___type === null) ? <>
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
						</> : <>
							{(() => {
								switch (this.state.ticket___type) {
									case 'standard': return <>
										{(this.state.ticket___props___is_loading === true) ? <>
											<br />
											<br />
											<br />
											<br />
											<ComSpinnerComponent size="small"/>
										</> : <>
											<br />
											<ComQrViewComponent qrCode_content={ lib_names_shared.endpoints.qrs_nopr_standard + ___share_url___only_code }/>
											<section className="md-ticket-view---container---writed">
												<h2>{ this.state.ticket___props['ticket_person_name'] }</h2>
												<h5>per:</h5>
												<h4>
													{(this.state.event___props___is_loading === true) ? <>
														<IonSkeletonText animated={ true } style={{width:'50vw'}}></IonSkeletonText>
													</> : <>
														<span>{ this.state.event___props['event_name'] }</span>
													</>}
												</h4>
												<br />
												{(this.state.ticket___type_props___is_loading !== true) ? <>
													<ComTicketTypeComponent ticket_type___props={ this.state.ticket___type_props } show___description={ true }/>
												</> : <></>}
												<br />
												<p>{ this.state.ticket___props['ticket_notes'] }</p>
											</section>
											<br />
											<br />
										</>}
									</>; break;
									case 'invite': return <>
										{(this.state.event___props___is_loading === true || this.state.user___props___is_loading === true) ? <>
											<br />
											<br />
											<br />
											<br />
											<ComSpinnerComponent size="small"/>
										</> : <>
											<br />
											<ComQrViewComponent qrCode_content={ lib_names_shared.endpoints.qrs_nopr_invite + ___share_url___only_code }/>
											<br />
											<section className="md-ticket-view---container---writed">
												<h5>Invito per:</h5>
												<h2>{ this.state.event___props['event_name'] }</h2>
												<h3>il <span>{ this.funcs___datetime.datetime___get___date(this.state.event___props['event_datetime_begin']) }</span> alle <span>{ this.funcs___datetime.datetime___get___time(this.state.event___props['event_datetime_begin']) }</span></h3>
												<h5>da parte di</h5>
												{(this.state.user___props['user_name_displayed'] && this.state.user___props['user_name_displayed'].length > 0) ? <>
													<h4>{ this.state.user___props['user_name_displayed'] }</h4>
												</> : <>
													<h4>{ this.state.user___props['user_name'] + ' ' + this.state.user___props['user_surname'] }</h4>
												</>}
											</section>
											<br />
											<br />
										</>}
									</>; break;
								}
							})()}
						</>}
					</section>
				</IonContent>

				<IonFooter>
					{(() => {
						switch (this.state.ticket___type) {
							case 'online':
							case 'standard': return <>
								{(this.state.ticket___props___is_loading === false && this.state.user___props___is_loading === false) ? <>
									<section className="container---footer-section">
										<ComButtonTicketShareComponent ticket_type='standard' ticket_code={ ___share_url___only_code } ticket_event_props={ this.state.event___props } ticket_dom_reference={ this.MdTicketViewModal___ticket_dom_reference }/>
									</section>
								</> : <></>}
							</>; break;
							case 'invite': return <>
								{(this.state.event___props___is_loading === false && this.state.user___props___is_loading === false) ? <>
									<section className="container---footer-section">
										<ComButtonTicketShareComponent ticket_type='invite' ticket_code={ ___share_url___only_code } ticket_event_props={ this.state.event___props } ticket_dom_reference={ this.MdTicketViewModal___ticket_dom_reference }/>
									</section>
								</> : <></>}
							</>; break;
						}
					})()}
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdTicketViewModal);