import React from 'react';
import { IonActionSheet, IonAlert, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonSelect, IonSelectOption, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { IonInfiniteScrollCustomEvent } from '@ionic/core';

//	S T Y L E

import './sb-tickets-list.scss';

//	S T A T E   -   P R O P S

import { props___SbTicketsListPage, state___SbTicketsListPage, state___SbTicketsListPage___default } from './sb-tickets-list.state';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../../interfaces/interface.actionsheet';
import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	F U N C S

import { funcs_tickets } from '../../../funcs/funcs.tickets';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-event/com-event';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComSpinnerButtonComponent from '../../../components/com-spinner-button/com-spinner-button';
import ComSpinnerMoreComponent from '../../../components/com-spinner-more/com-spinner-more';
import ComSpinnerRefreshComponent from '../../../components/com-spinner-refresh/com-spinner-refresh';
import ComTicketComponent from '../../../components/com-ticket/com-ticket';

//	M O D A L S

import MdTicketViewModal from '../../../modals/md-ticket-view/md-ticket-view';

//	C L A S S

class SbTicketsListPage extends React.Component<props___SbTicketsListPage, state___SbTicketsListPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___tickets: funcs_tickets = new funcs_tickets();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly SbTicketsListPage___IonAlert___logout_check: interface___IonAlert = {
		header: 'Aspetta',
		message: 'Vuoi davvero effettuare il logout?',
		buttons: [
			{
				text: 'Si',
				handler: () => { 




					this.setState({ alert___delete_ticket___visibility: false });
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({ alert___delete_ticket___visibility: false });
				}
			}
		]
	};

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonActionSheet___ticket_options = () : interface___IonActionSheet => {
		const ___button___view: any = [{
			text: 'Visualizza',
			handler: () => { 
				this.setState({
					modal___ticket_selected___visibile: true,
					actionSheet___ticket_selected___visibile: false,
				});
			}
		}];
	//	const ___button___edit: any = [{
	//		text: 'Modifica',
	//		handler: () => { 
	//			
	//			
	//		}
	//	}];
		const ___button___delete: any = (this.state.ticket_selected___target && this.state.ticket_selected___target['ticket_used'] !== true && this.state.event___props['event_ended'] !== true && this.state.event___props['event_is_now'] !== true) ? [{
			text: 'Elimina',
			role: 'destructive',
			handler: () => { 
				this.setState({
					alert___delete_ticket___visibility: true,
					actionSheet___ticket_selected___visibile: false,
				});









				
			}
		}] : [];
		return { buttons: [
			...___button___view,
	//		...___button___edit,
			...___button___delete,
			{
				text: 'Annulla',
				role: 'cancel',
			}
		]}
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbTicketsListPage,
	)
	{
		super(props);
		this.state = state___SbTicketsListPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___event = () => {
		this.setState({
			event___id: localStorage.getItem(lib_names.localStorage.user___event_selected)!,
		}, async () => {
			const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
			if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
			this.setState({
				event___props: ___temp___event___props.data,
				event___props___is_loading: false,
			}, async () => {
				await this.read_props___tickets___collection();
			});
		});
	}

	private read_props___tickets___collection = async () => {
		const ___temp___tickets_collection: type___api___response = await this._API.tickets___read___multi(this.state.event___id, this.state.tickets___collection___target, null, this.state.tickets___collection.length, this.state.tickets___collection___sort);
		if (!___temp___tickets_collection || ___temp___tickets_collection.response === 'error' || ___temp___tickets_collection.data === null) { console.log('404'); }
		this.setState((prevState) => ({
			tickets___collection: [...prevState.tickets___collection, ...___temp___tickets_collection.data],
			tickets___collection___is_loading: (___temp___tickets_collection.response === 'success' || ___temp___tickets_collection.response === 'no-data') ? false : true,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.read_props___event();
		___e.detail.complete();
	}

	private handle___page___load_more = (___e: IonInfiniteScrollCustomEvent<void> | null) => {
		this.setState({
			tickets___collection___is_loading: true,
		}, async () => {
			await this.read_props___tickets___collection();
			if (___e !== null) { ___e.target.complete(); }
		})
	}

	private handle___ticket___onClick = (___ticket_props: any) => {

		console.log('___ticket_props', ___ticket_props)
		const ___ticket_props___ticket_id: string | null = (___ticket_props['ticket_type'] === 'invite') ? null : ___ticket_props['ticket_type'];
		this.setState({
			ticket_selected___target: ___ticket_props,
			ticket_selected___target_object: this.funcs___tickets.ticket___encrypt(___ticket_props['ticket_type'], ___ticket_props___ticket_id, ___ticket_props['ticket_event'], ___ticket_props['ticket_pr'])['clear'],
			actionSheet___ticket_selected___visibile: true
		});
	}








	private handle___tickets___change_viewable = (___event: any) => {
		this.setState({
			tickets___collection: [],
			tickets___collection___is_loading: true,
			tickets___collection___target: ___event.detail.value,
		}, async () => {
			await this.read_props___tickets___collection();
		});
	}

	private handle___tickets___sort = (___event: any) => {
		this.setState({
			tickets___collection: [],
			tickets___collection___is_loading: true,
			tickets___collection___sort: ___event.detail.value,
		}, async () => {
			await this.read_props___tickets___collection();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewWillEnter() : void {
		this.read_props___event();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>

			<IonAlert isOpen={ this.state.alert___delete_ticket___visibility } { ...this.SbTicketsListPage___IonAlert___logout_check } />
			<IonActionSheet isOpen={ this.state.actionSheet___ticket_selected___visibile } { ...(this.element___IonActionSheet___ticket_options()) } onDidDismiss={() => { this.setState({ actionSheet___ticket_selected___visibile: false }); }} />
			
			<MdTicketViewModal isOpen={ this.state.modal___ticket_selected___visibile && this.state.ticket_selected___target_object !== null } ticket_object={ this.state.ticket_selected___target_object } event___onDidDismiss={() => { this.setState({ modal___ticket_selected___visibile: false, ticket_selected___target: null, ticket_selected___target_object: null }); }} />

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefreshComponent/>
					</IonRefresher>

					<section className="sb-tickets-list---header">
						<h3>Evento Selezionato</h3>
						<br />
						{(this.state.event___props___is_loading === false && this.state.event___props !== null) ? <>
							<IonCard>
								<ComEventComponent event___props={ this.state.event___props }/>
							</IonCard>
							<br />
							<IonCard>
								<IonList>
									{(this.props.me && this.props.me['user_role_is___admin'] === true && this.state.event___props) ? <>
										<IonItem button={ true } className="is-ion-item-button" routerLink={ '/analytics/global/' + this.state.event___props['event_id'] }>
											<i className="fas fa-chart-line"></i>
											<IonLabel>Statistiche</IonLabel>
										</IonItem>
									</> : <></>}
									<IonItem button={ true } className="is-ion-item-button" routerLink={ '/analytics/me/' + this.state.event___props['event_id'] }>
										<i className="fas fa-chart-line"></i>
										<IonLabel>Statistiche Personali</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>
						</> : <>
							<ComSpinnerButtonComponent/>
						</>}
						<br />
						<h3>Lista Tickets</h3>
					</section>

					<section className="sb-tickets-list---options-selector">
						<IonSelect key="view___" interface="popover" label="Visualizza" placeholder="Solo i Miei Ticket" onIonChange={ this.handle___tickets___change_viewable }>
							{(this.props.me && this.props.me['user_role_is___admin'] === true) ? <>
								<IonSelectOption key="view___all" value="all">Tutti i Ticket</IonSelectOption>
								{(this.state.event___props !== null && (this.state.event___props['event_ended'] === true || this.state.event___props['event_is_now'] === true)) ? <>
									<IonSelectOption key="view___all_used" value="all_used">Tutti i Ticket (Usati)</IonSelectOption>
								</> : <></>}
							</> : <></>}
							<IonSelectOption key="view___me" value="me">Miei Ticket</IonSelectOption>
							{(this.state.event___props !== null && (this.state.event___props['event_ended'] === true || this.state.event___props['event_is_now'] === true)) ? <>
								<IonSelectOption key="view___me_used" value="me_used">Miei Ticket (Usati)</IonSelectOption>
							</> : <></>}
						</IonSelect>
						<IonSelect key="sort___" interface="popover" label="Ordina per" placeholder="Standard" onIonChange={ this.handle___tickets___sort }>
							<IonSelectOption key="sort___standard" value="standard">Standard</IonSelectOption>
							<IonSelectOption key="sort___name_asc" value="name_asc">Nome (A-Z)</IonSelectOption>
							<IonSelectOption key="sort___name_desc" value="name_desc">Nome (Z-A)</IonSelectOption>
							<IonSelectOption key="sort___date_added_asc" value="date_added_asc">Data di Aggiunta (Su)</IonSelectOption>
							<IonSelectOption key="sort___date_added_desc" value="date_added_desc">Data di Aggiunta (Giu)</IonSelectOption>
						</IonSelect>
					</section>

					<IonList>
						{this.state.tickets___collection.map((___t: any, ___t_idx: number) => {
							const ___show_pr: boolean =  this.state.tickets___collection___target === 'all' || this.state.tickets___collection___target === 'all_used';
							return <>
								<section key={ ___t_idx }>
									<ComTicketComponent e_key={ ___t_idx } ticket___show_pr={ ___show_pr} ticket___props={ ___t } onClick={() => { this.handle___ticket___onClick(___t);  }}/>
								</section>
							</>;
						})}
					</IonList>

					{(this.state.tickets___collection___is_loading === false) ? <>
						<section onClick={() => { this.handle___page___load_more(null); }}>
							<ComListEmptyComponent text="Carica altro..."/>
						</section>
					</> : <>
						<ComSpinnerMoreComponent/>
						{/*<ComListEmptyComponent text={ this.state.tickets___collection.length > 0 ? 'Nient altro da caricare...' : 'Niente da visualizzare...' }} /> */}
					</>}

				</IonContent>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbTicketsListPage);